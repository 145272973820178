const { intersection } = require('lodash')

const iconMenu = document.querySelector('.menu__icon')
if (iconMenu) {
    const menuBody = document.querySelector('.menu__body')
    const headerColor = document.querySelector('.header__color')
    const menuConnect = document.querySelector(".menu__connect")
    iconMenu.addEventListener('click', function (e) {
        document.body.classList.toggle('lock')
        iconMenu.classList.toggle('active')
        menuBody.classList.toggle('active')
        menuConnect.classList.toggle('btn')
        if(headerColor) {
            headerColor.classList.toggle('active')
        }

        //Выход с бургер меню по клику на ссылку
        if(document.querySelector('.menu__body.active')) {
            const menuLink = document.querySelectorAll(".menu__link")
            
            menuLink.forEach((e) => {
                e.addEventListener('click', function () {
                    menuBody.classList.remove('active')
                    iconMenu.classList.remove('active')
                    document.body.classList.remove('lock')
                    if(menuConnect.classList.contains('btn')) {
                        menuConnect.classList.remove('btn')
                    }
                    if(headerColor) {
                        headerColor.classList.remove('active')
                    }
                })
                
            })
            
            menuConnect.addEventListener('click', () =>  {
                menuBody.classList.remove('active')
                iconMenu.classList.remove('active')
                document.body.classList.remove('lock')
                if(menuConnect.classList.contains('btn')) {
                    menuConnect.classList.remove('btn')
                }
                if(headerColor) {
                    headerColor.classList.remove('active')
                }
            })
        }
    })
}

// Доп класс при скролле
var header = document.querySelector('.header')

window.onscroll = function () {
    let scrolled = window.pageYOffset || document.documentElement.scrollTop

    if (scrolled > 70) {
        header.classList.add('scrolled')
    }
    if (71 > scrolled) {
        header.classList.remove('scrolled')
    }
}

//// Вкл воспроизведения tgs-player
// const playerLoadingOptions = {
//     threshold: 0
// }
// const playerLoading = function ( entry, observer) {
//     entry.forEach( tgs => {
//         const { target, isIntersecting, intersectionRatio} = tgs;

//         if (isIntersecting) {
//             target.play()
//         } else {
//             target.pause()
//         }
//     })
// }
// const observer = new IntersectionObserver( playerLoading, playerLoadingOptions)
// const targetTgs = document.querySelectorAll("tgs-player")

// targetTgs.forEach(function(item) {
//     observer.observe(item)
// })

const script = document.createElement('script')
script.type = 'text/javascript'

script.async = true

script.src =
    'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.10.1/lottie_light.min.js'
script.crossorigin = 'anonymous'
script.referrerpolicy = 'no-referrer'

script.onload = () => {
    const containers = document.querySelectorAll('.tgs-container')
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const { target, isIntersecting } = entry

            if (isIntersecting) {
                lottie.play(target.animationName)
            } else {
                lottie.pause(target.animationName)
            }
        })
    })

    containers.forEach((container, i) => {
        const animationName = `animation=${i}`
        const animation = lottie.loadAnimation({
            container: container,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: container.dataset.src,
            name: animationName
        })
        animation.setSubframe(false)
        container.animationName = animationName
        observer.observe(container)
    })
}
document.body.appendChild(script)
