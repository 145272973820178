import Swiper from 'swiper/bundle';

const slider = document.querySelector(".swiper")
let swiper;
if(document.querySelector('.monitoring')) { 
  function mobileSwiper () {
    if(window.innerWidth <= 575 && slider.dataset.mobile == 'false') {
  
      swiper = new Swiper(slider, {
        effect: 'cards',
        width: 303,
        centeredSlides: true,
        slidesPerGroup: 1,
        speed: 500,
        pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true,
              dynamicMainBullets: 3,
            },
        cardsEffect: {
          perSlideOffset: 10,
          centeredSlides: true,
          rotate: false,
        },
      });
      slider.dataset.mobile = 'true';
    } 
    
    if (window.innerWidth > 575) {
      slider.dataset.mobile = 'false';
      if (slider.classList.contains('swiper-initialized')) {
        swiper.destroy();
      }
    }
  
  }
  mobileSwiper();
  
  window.addEventListener('resize', () => {
    mobileSwiper();
  });
}
